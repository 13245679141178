import axios from "axios";

import { GameWithImageUrl } from "@/lib/game/GameService";
import { ChessGamePreview } from "../../types/Move";
import { Privacy } from "@/prisma/schema/mysql";
interface GameQueryFilterParams {
  collectionId?: string;
  page: number;
  pageSize: number;
  orderBy?: string;
  order?: string;
  tId?: string;
  uId?: string;
  pgn?: string | null;
  privacy?: Privacy | null;
  username?: string | null;
  restOfSearch?: string | null;
  player?: string | null;
  gameSource?: string | null;
}

export interface QueryGamesResponse<T> {
  count: number;
  games: T[];
}

export async function queryGamesImpl<T>(
  baseUrl: string,
  params: GameQueryFilterParams,
): Promise<QueryGamesResponse<T>> {
  const { data, status } = await axios.get<QueryGamesResponse<T>>(baseUrl, {
    params,
  });

  return data;
}

export interface SelectedGamePreview extends ChessGamePreview {
  selected: boolean;
}

export const queryGames = queryGamesImpl<ChessGamePreview>;
export const querySelectedGames = queryGamesImpl<SelectedGamePreview>;

export async function queryGamesImages(game_ids: string[]) {
  const { data } = await axios.post<GameWithImageUrl[]>(
    "/api/games/images",
    game_ids,
    {
      headers: {
        "content-type": "application/json",
      },
    },
  );
  return data;
}
