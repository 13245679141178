import { logger } from "@/lib/logging/logger";
import axios from "axios";
import { ChessGamePreview } from "@/types";
import { Move, toggleMoveColor } from "@/types/Move";
import { Dispatch, SetStateAction } from "react";
import {
  filterSchema,
  filterSchemaType,
  parseAllParams,
} from "@/lib/validators/queryParamsFiltersTest";

type GetGamesProps = filterSchemaType;

export const getGames = async (
  props: GetGamesProps,
): Promise<ChessGamePreview[]> => {
  const params = parseAllParams(filterSchema, props);
  logger.info("get games params", params);
  const { data, status } = await axios.get<ChessGamePreview[]>("/api/games", {
    params,
  });
  return data;
};

export const addMove = (
  value: string,
  index: number,
  moves: Move[] | undefined,
  setMoves: Dispatch<SetStateAction<Move[] | undefined>>,
  setUpdating: Dispatch<SetStateAction<boolean>>,
) => {
  if (moves !== undefined) {
    const move = { ...moves[index >= 0 ? index : index + 1] };
    move.bbox = [20, 20, 90, 20];
    move.user_selected_move = "change";
    move.prediction = value;
    move.predictions = [value];
    move.probabilities = [1.0];
    if (index === -1) {
      move.player = "white";
      move.move_idx = 1;
    } else if (move.player === "white") {
      move.player = "black";
    } else if (move.player === "black") {
      move.player = "white";
      move.move_idx += 1;
    }
    const movesRight = toggleMoveColor(moves.slice(index + 1), "add");
    setMoves([...moves.slice(0, index + 1), move, ...movesRight]);
    setUpdating(false);
  }
};

export const returnAddMove = (
  value: string,
  index: number,
  moves: Move[] | undefined,
) => {
  if (moves !== undefined) {
    const move = { ...moves[index >= 0 ? index : index + 1] };
    move.bbox = [20, 20, 90, 20];
    move.user_selected_move = "change";
    move.prediction = value;
    move.predictions = [value];
    move.probabilities = [1.0];
    if (index === -1) {
      move.player = "white";
      move.move_idx = 1;
    } else if (move.player === "white") {
      move.player = "black";
    } else if (move.player === "black") {
      move.player = "white";
      move.move_idx += 1;
    }
    const movesRight = toggleMoveColor(moves.slice(index + 1), "add");
    return [...moves.slice(0, index + 1), move, ...movesRight];
  }
};

export const deleteMove = (
  index: number,
  moves: Move[] | undefined,
  setMoves: Dispatch<SetStateAction<Move[] | undefined>>,
  setUpdating: Dispatch<SetStateAction<boolean>>,
) => {
  if (moves !== undefined) {
    const movesRight = toggleMoveColor(moves.slice(index + 1), "remove");
    const newState = [...moves.slice(0, index), ...movesRight];
    setMoves(newState);
    setUpdating(false);
  }
};

export const returnDeleteMove = (index: number, moves: Move[] | undefined) => {
  if (moves !== undefined) {
    const movesRight = toggleMoveColor(moves.slice(index + 1), "remove");
    const newState = [...moves.slice(0, index), ...movesRight];
    return newState;
  }
};

export const handleMoveChange = (
  move: Move,
  index: number,
  setMoves: Dispatch<SetStateAction<Move[] | undefined>>,
  setUpdating: Dispatch<SetStateAction<boolean>>,
) => {
  setMoves((moves) => moves?.map((obj, id) => (id === index ? move : obj)));
  setUpdating(false);
};
