"use client";

import ChessboardUpload from "@/app/(dashboard)/components/ChessboardUpload";
import Logo from "@/app/(dashboard)/components/Logo";
import { ClientUser } from "@/types/ClientUser";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { UserAccountNav } from "./UserAccountNav";
import { useUserContext } from "./providers/user-provider";
import { cn, getCredits } from "@/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";
import { isAdminUser } from "@/lib/utils";
import { SvgDiamond } from "./svgRepo";

interface NavbarProps {
  user: ClientUser | null;
}

function Navbar({ user }: NavbarProps) {
  const [hideNavbar, setHideNavbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // Sticky Navbar
  const [sticky, setSticky] = useState(false);
  const pathname = usePathname();
  const userContext = useUserContext();
  var timer: any = null;
  const isAdmin = isAdminUser(user);

  const handleStickyNavbar = () => {
    if (window.scrollY >= 40) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleStickyNavbar);

    window.addEventListener(
      "scroll",
      function () {
        setHideNavbar(false);
        if (timer !== null) {
          clearTimeout(timer);
        }
        timer = setTimeout(function () {
          setHideNavbar(true);
        }, 10000);
      },
      false,
    );
  }, []);

  if (userContext.imageFullScreen) {
    return;
  }

  return (
    <header
      className={cn(
        `header  flex w-full relative z-10 items-center bg-primary-foreground border-b ${
          sticky
            ? "!bg-white !bg-opacity-80 shadow-sticky backdrop-blur-sm !transition drop-shadow-md "
            : ""
        }`,
        sticky && hideNavbar && !isLoading ? "hidden" : "",
      )}
    >
      <div className="hidden h-full sm:flex sm:h-fit max-w-7xl mx-auto py-1 items-center justify-between w-full">
        <div className="flex w-full justify-between mx-5">
          {/* Primary menu and logo */}
          <div className="flex items-center justify-between space-x-2 w-full">
            {/* logo */}
            <div className="flex-col flex lg:flex-row items-center lg:space-x-3">
              <Logo isLoading={isLoading} />
              <ChessboardUpload user={user} setIsLoading={setIsLoading} />
            </div>
            {/* primary */}
            <div className="hidden sm:flex gap-8 items-center">
              <Link
                href="/"
                className={pathname === "/" ? "border-b-2 font-bold" : ""}
              >
                Home
              </Link>
              <Link
                className={
                  pathname.startsWith("/game") &&
                  !pathname.includes("new_batch")
                    ? "border-b-2 font-bold"
                    : ""
                }
                href="/games"
              >
                Games
              </Link>
              {isAdmin && (
                <Link
                  className={
                    pathname === "/tournaments" ? "border-b-2 font-bold" : ""
                  }
                  href="/tournaments"
                >
                  Tournaments
                </Link>
              )}

              {!user && (
                <Link
                  className={
                    pathname === "/sign-in" ? "border-b-2 font-bold" : ""
                  }
                  href="/sign-in"
                >
                  Sign In
                </Link>
              )}
              {user && (
                <div className="flex items-center gap-4">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Link
                          className="flex gap-1 border-r-2 justify-center items-center border-slate-500 pr-4"
                          href="/pricing"
                        >
                          <SvgDiamond />
                          {getCredits(user.apiLimit)}
                        </Link>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p>Free credits: {user.apiLimit?.freeCredits ?? 0}</p>
                        <p>Plan credits: {user.apiLimit?.planCredits ?? 0}</p>
                        <p>Otp credits: {user.apiLimit?.otpCredits ?? 0}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <UserAccountNav user={user} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* mobile navigation */}
      <div className="sm:hidden flex-col w-full">
        {!sticky && (
          <div className="py-1">
            <ChessboardUpload user={user} setIsLoading={setIsLoading} />
          </div>
        )}
      </div>
    </header>
  );
}

export default Navbar;
