import {
  moveFromEnglishToRomanian,
  moveFromRomanianToEnglish,
} from "@/lib/utils";
import { ChessGamePreviewQuery } from "@/lib/validators/game-preview-query";
import { AiAnalysis } from "@/prisma/schema/mongo";
import {
  AttachedPGN,
  GameSource,
  GameStatus,
  Privacy,
  Tournament,
  User,
} from "@/prisma/schema/mysql";
import { Globe, Link, Lock, LucideIcon, Users } from "lucide-react";
import pgnParser from "pgn-parser";
import { PublicUser } from "./ClientUser";

export type Player = "white" | "black";
export type Operation = "add" | "remove";

export interface Move {
  // All these should actually be optional
  bbox: number[];
  prediction: string;
  predictions: string[];
  probabilities: number[];
  predictions_moves: string[];
  probabilities_moves: number[];
  strike_prob: number;
  move_len: number;
  player: string;
  move_idx: number;
  move_en: string;
  move_ro: string;
  fischer_score: number;
  prediction_is_changed: boolean;
  target_ids: string[];
  prediction_pos_from: string;
  prediction_pos_to: string;
  user_selected_move: string;
  highlight: boolean;
  ocr_value: string;
  is_start_of_table: boolean;
  comments?: string;
  is_insertion?: boolean;
  // PLC ITEMS:
  piece?: PLCItem;
  line?: PLCItem;
  column?: PLCItem;
  capture?: PLCItem;
  castle?: PLCItem;
  two_cols?: PLCItem;
  src_col?: PLCItem;
}

export interface PLCItem {
  [key: string]: number | null;
}

export const initializeSelectedMove = (move: Move) => {
  if (move.user_selected_move === undefined || move.user_selected_move === "") {
    move.user_selected_move = move.prediction;
  }
  move.highlight = false;
  if (!move.ocr_value) {
    move.ocr_value = move.prediction;
  }
  // move.ocr_value = move.prediction;
  // move.comments = "";
};

export const parsePgnOrNull = (pgn: string) => {
  try {
    const [parsedPgn] = pgnParser.parse(pgn);
    return parsedPgn;
  } catch (e) {
    return null;
  }
};

export const getMovesFromPGN = (pgn: string): Move[] | null => {
  const parsedPgn = parsePgnOrNull(pgn) ?? parsePgnOrNull(pgn + " *");
  if (!parsedPgn) return null;
  return parsedPgn.moves.map((x, idx) => {
    const moveRo = moveFromEnglishToRomanian(x.move);
    return {
      move_en: x.move,
      player: x.move_number ? "white" : "black",
      bbox: [0, 0, 0, 0],
      probabilities: [1],
      prediction: moveRo,
      predictions: [moveRo],
      move_ro: moveRo,
      user_selected_move: moveRo,
      is_start_of_table: idx === 0,
      ocr_value: moveRo,
      move_idx: Math.floor(idx / 2) + 1,
    };
  }) as Move[];
};

export const getPGNString = (moves: Move[] | undefined): string => {
  let pgn = "";
  if (moves === undefined || moves.length === 0) {
    return pgn;
  }
  moves.forEach((move) => {
    if (move.player === "white") {
      pgn += move.move_idx + ". ";
    }
    pgn += moveFromRomanianToEnglish(move.user_selected_move) + " ";
  });
  if (
    moves[moves.length - 1] &&
    moves[moves.length - 1].user_selected_move.includes("#")
  ) {
    if (moves[moves.length - 1].player === "white") pgn += "1-0";
    else if (moves[moves.length - 1].player === "black") pgn += "0-1";
  } else pgn += "*";
  return pgn;
};

export const movesToPgnNewLine = (moves: Move[] | undefined): string => {
  let pgn = "";
  if (moves === undefined || moves.length === 0) {
    return pgn;
  }
  moves.forEach((move) => {
    if (move.player === "white") {
      pgn += move.move_idx + ". ";
      pgn += moveFromRomanianToEnglish(move.user_selected_move) + " ";
    } else {
      pgn += moveFromRomanianToEnglish(move.user_selected_move) + "\n";
    }
  });
  return pgn;
};

export const movesToPgnList = (moves: Move[] | undefined): string[] => {
  let pgn = [];
  if (moves === undefined || moves.length === 0) {
    return [];
  }
  let moveStr = "";
  moves.forEach((move) => {
    if (move.player === "white") {
      moveStr += move.move_idx + ". ";
      moveStr += moveFromRomanianToEnglish(move.user_selected_move) + " ";
    } else {
      moveStr += moveFromRomanianToEnglish(move.user_selected_move);
      pgn.push(moveStr);
      moveStr = "";
    }
  });
  if (moveStr !== "") {
    pgn.push(moveStr);
  }
  return pgn;
};

export const getGameAccuracy = (moves: Move[], humanPgn: string, topK = 20) => {
  if (!humanPgn) {
    return null;
  }
  // const totalMoves = moves.length;
  const aiMoves: string[] = movesToPgnList(moves) as string[];
  const pgnMoves: string[] = formatPGN(humanPgn, true) as string[];

  const totalMoves = pgnMoves.length;
  const aiMovesLength = aiMoves.length;
  const movesCount = Math.min(totalMoves, aiMovesLength, topK);

  let accuracy = 0;
  for (let i = 0; i < movesCount; i++) {
    if (pgnMoves[i] === aiMoves[i]) {
      accuracy++;
    }
  }

  return {
    accuracy: accuracy / movesCount,
    totalMoves: totalMoves,
    aiMovesLength: aiMovesLength,
    movesCount: movesCount,
    correct: accuracy,
    aiMoves,
    pgnMoves,
  };
};

export type GameAccuracy = ReturnType<typeof getGameAccuracy>;

export function formatPGN(pgn: string, returnList = false): string | string[] {
  // Split the PGN string by spaces and filter out empty strings
  const moves = pgn.split(/\s+/).filter((move) => move.trim() !== "");

  // Initialize variables to store formatted moves
  let formattedMoves: string[] = [];
  let whiteMove = "";
  let blackMove = "";

  let i = 0;
  while (i < moves.length) {
    whiteMove = moves[i + 1];
    blackMove = moves[i + 2];
    let string = `${Math.floor(i / 3) + 1}. `;
    if (whiteMove) string += whiteMove + " ";
    if (blackMove) string += blackMove;
    formattedMoves.push(string);
    i += 3;
  }

  if (returnList) {
    return formattedMoves;
  }
  // Join formatted moves with line breaks
  const formattedPGN = formattedMoves.join("\n");
  return formattedPGN;
}

export interface PgnChessGame {
  uuid: string;
  pgn: string;
  comments: string | null;
  images: any;
  detections: Move[];
  userId: string | null;
  name: string | null;
}

export interface ComputedGameScore {
  fischerScoreTotal: number;
  fischerScoreAvg: number;
  ocrAccuracy: number;
  good: number;
  total: number;
}

export interface GameScore extends ComputedGameScore {
  cnt_insertions?: number | null;
  cnt_deletions?: number | null;
}
export interface ChessGame {
  id: string;
  uuid: string;
  pgn: string;
  initialPgn?: string | null;
  humanPgn?: string | null;
  has_both_players: boolean;
  images: any;
  detections: Move[];
  initialDetections?: Move[];
  userId: string | null;
  comments: string | null;
  gameScore?: GameScore;
  tournament: Tournament | null;
  gameHistory?: ChessGamePreview[];
  originalGameId?: string;
  name?: string;
  gameDate?: Date;
  privacy?: Privacy;
  user: PublicUser | null;
  status: GameStatus;
  attachedPgn?: AttachedPGN | null;
  gameSource: GameSource;
  createdAt?: Date;
  aiAnalysis?: AiAnalysis;
}

export interface PgnUploadChessGame {
  uuid: string;
  pgn: string;
  comments: string | null;
  images: string[];
  // detections: Move[];
  userId: string | null;
  name: string | null;
  tournament?: string | null;
  date?: Date | null;
  white?: string | null;
  black?: string | null;
}

export type PgnUpload = {
  id: string;
  uuid: string;
  pgn: string | null;
  tournament: string | null;
  date: string | null;
  name: string | null;
  white: string | null;
  black: string | null;
  images: string | null;
  user: Pick<User, "id" | "email" | "name"> | null;
};

export type ChessGamePreview = Pick<
  PgnUpload,
  "id" | "uuid" | "pgn" | "name" | "user"
> & {
  createdAt: Date;
  updatedAt: Date;
  images: any;
  gameScore?: GameScore;
  tournament: string | null;
  humanPgn: string | null;
  has_both_players: boolean;
  detections?: Move[];
  initialDetections?: Move[];
  privacy?: Privacy;
  gameSource: GameSource;
  status: GameStatus;
  imgUrl: string | null;
  attachedPgn?: AttachedPGN | null;
  opening?: string;
};

export type TournamentPreviewWithGame = Tournament & { user: User | null } & {
  games: {
    id: string;
  }[];
};

export type ChessGamePreview2 = Omit<
  PgnUpload,
  "tournament" | "white" | "black"
> & {
  createdAt: Date;
  updatedAt: Date;
};

// Conversion function

export const fromGamesQueryStrToGamePreviewsObj = (
  games: ChessGamePreviewQuery[],
): ChessGamePreview[] => {
  return games.map((game) => {
    const chessPrev: ChessGamePreview = {
      id: game.id,
      name: game.name,
      uuid: game.uuid,
      pgn: game.pgn,
      humanPgn: game.humanPgn ?? null,
      images: JSON.parse(game.images),
      createdAt: game.createdAt,
      updatedAt: game.updatedAt,
      user: game.user,
      tournament: game?.tournament,
      has_both_players: game.has_both_players,
      privacy: game.privacy,
      status: game.gameStatus,
      gameScore: game.gameScore,
      attachedPgn: game.attachedPgn,
      gameSource: game.gameSource,
      opening: game.opening,
      imgUrl: null,
    };
    return chessPrev;
  });
};

export const fromPgnQueryStrToGamePreviewsObj = (
  games: ChessGamePreviewQuery[],
): ChessGamePreview[] => {
  return games.map((game, index) => {
    const chessPrev: ChessGamePreview = {
      id: game.id,
      name: game.name,
      uuid: game.uuid,
      pgn: game.pgn,
      humanPgn: game.humanPgn ?? null,
      images: JSON.parse(game.images),
      tournament: game?.tournament,
      createdAt: game.createdAt,
      updatedAt: game.updatedAt,
      user: game.user,
      has_both_players: game.has_both_players,
      status: game.gameStatus,
      gameSource: game.gameSource,
      imgUrl: null,
    };
    return chessPrev;
  });
};

export const getMovesToRecompute = (moves: Move[]): Move[] => {
  return moves.map((move_, index) => {
    let move = Object.assign({}, move_);
    // let move = JSON.parse(JSON.stringify(move));
    if (move.user_selected_move !== move.prediction) {
      move.prediction = move.user_selected_move;

      // move.move_ro = move.user_selected_move;
      // move.move_en = moveFromRomanianToEnglish(move.user_selected_move);
      // move.predictions = [move.move_en];
      // move.probabilities = [1.0];
    }
    move.user_selected_move = "";
    return move;
  });
};

export const toggleMoveColor = (
  moves: Move[],
  operation: Operation,
): Move[] => {
  return moves?.map((move: Move, id) => {
    if (move.player === "white") {
      let idx = move.move_idx;
      if (operation === "remove") {
        idx = move.move_idx - 1;
      }

      return { ...move, player: "black", move_idx: idx };
    } else {
      let idx = move.move_idx;
      if (operation === "add") {
        idx = move.move_idx + 1;
      }

      return { ...move, player: "white", move_idx: idx };
    }
  });
};
export function getGameScore(
  moves: Move[],
  paramAccuracy?: number,
): ComputedGameScore {
  if (!moves.length)
    return {
      fischerScoreTotal: 0,
      fischerScoreAvg: 0,
      ocrAccuracy: 0,
      good: 0,
      total: 0,
    };

  let fischerScore: number = 0;
  let ocrAccuracy = 0;
  for (let i = 0; i < moves.length; ++i) {
    fischerScore += moves[i].fischer_score;
    if (
      moves[i].predictions &&
      moves[i].prediction === moves[i].predictions[0]
    ) {
      ocrAccuracy++;
    }
  }

  const gameScore = {
    fischerScoreTotal: fischerScore,
    fischerScoreAvg: fischerScore / moves.length,
    ocrAccuracy: paramAccuracy ?? ocrAccuracy / moves.length,
    good: ocrAccuracy,
    total: moves.length,
  };

  return gameScore;
}

export type PrivacyIcon = {
  icon: LucideIcon;
  privacy: Privacy;
  className: string;
};

export const privacyOptions: PrivacyIcon[] = [
  {
    icon: Globe,
    privacy: Privacy.PUBLIC,
    className: "text-sky-400",
  },

  {
    icon: Users,
    privacy: Privacy.USERS,
    className: "text-green-400",
  },

  {
    icon: Link,
    privacy: Privacy.UNLISTED,
    className: "text-red-400",
  },

  {
    icon: Lock,
    privacy: Privacy.PRIVATE,
    className: "text-slate-400",
  },
];
