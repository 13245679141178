import axios from "axios";
import { logger } from "@/lib/logging/logger";
import {
  filterSchemaCollections,
  filterSchemaTypeCollections,
  parseAllParams,
  possibleOrderByValuesCollections,
} from "@/lib/validators/queryParamsFiltersTest";
import { CollectionsQueryResult } from "../api/collections/public/route";

export type TpossibleOrderByValuesCollections =
  (typeof possibleOrderByValuesCollections)[number];

export function parseCollectionFilter(
  orderBy: string | undefined,
): orderBy is TpossibleOrderByValuesCollections {
  return possibleOrderByValuesCollections.includes(
    orderBy as TpossibleOrderByValuesCollections,
  );
}

type CollectionsQueryParams = Pick<
  filterSchemaTypeCollections,
  "page" | "pageSize" | "orderBy" | "order" | "name"
>;

export async function queryCollections(
  baseUrl: string,
  queryParams: CollectionsQueryParams,
): Promise<CollectionsQueryResult> {
  const params = parseAllParams(filterSchemaCollections, queryParams);
  try {
    const { data } = await axios.get<CollectionsQueryResult>(baseUrl, {
      params,
    });
    return data;
  } catch (error) {
    logger.error(`GET ${baseUrl}`, error);
    return { count: 0, collections: [] };
  }
}
